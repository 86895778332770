import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { Routes, RouterModule } from '@angular/router';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { LibraryAuthModule } from 'clan-auth';
import { AuthMainComponent } from './auth-main/auth-main.component';
const routes: Routes = [
  {
    path: '',
    component: AuthMainComponent,
  },
];

@NgModule({
  declarations: [AuthMainComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    LibraryAuthModule,
  ],
})
export class AuthModule {}
