<div class="main-container">
  <div class="auth-page">
    <div>
      <div class="auth-page-qr">
        <!-- <qrcode
          [qrdata]=""
          [width]="180"
          [errorCorrectionLevel]="'H'"
          [elementType]="'svg'"
          [allowEmptyString]="true"
        ></qrcode> -->
      </div>
      <div>
        <app-auth-login-form></app-auth-login-form>
      </div>
    </div>
  </div>
</div>
