import { Component } from '@angular/core';

import { AuthObserver, LoginEvent } from 'clan-auth';

import { Router } from '@angular/router';
import { AuthorizationService } from '@core/services/authorization/authorization.service';
@Component({
  selector: 'app-auth-main',
  templateUrl: './auth-main.component.html',
  styleUrls: ['./auth-main.component.scss'],
})
export class AuthMainComponent {
  showClanLogo = false;

  constructor(
    private router: Router,
    private authObserver: AuthObserver,
    private authorizationService: AuthorizationService
  ) {
    this.showClanLogo = window.location.hostname === /*'localhost'*/ 'employer.pv-clan.ch';

    this.authObserver.observer.subscribe({
      next: (value) => {
        if (value instanceof LoginEvent) {
          this.loginCallback();
        }
      },
    });
  }

  loginCallback(): void {
    this.authorizationService.setIsAuthenticated(true);
    this.router.navigate(['/offers/table']);
  }
}
